/**
 *
 * Graphiql
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose } from 'redux'
import GraphiQL from 'graphiql'
import { createStructuredSelector } from 'reselect'
import { FormattedMessage } from 'react-intl'
import messages from './messages.js'
import _ from 'lodash'
import qs from 'query-string'
import prettier from 'prettier/standalone'
import graphqlParser from 'prettier/parser-graphql'

import LoadingAnim from 'components/LoadingAnim'
import { LAYERS } from 'utils/styleUtils'
import SelectBar from 'components/SelectBar'
import { exists } from 'utils/sonraiUtils'
import { getGraphEndpoint } from 'utils/graphDataUtils'
import { selectGraphQLSchema } from 'containers/SonraiData/selectors'
import { fromJS } from 'immutable'
import { buildClientSchema } from 'graphql'
import { metdataNestedObject, getNodeViewPushParams } from 'utils/sonraiUtils'
import { getCurrentOrg } from 'auth/current-org'

import './styles.css'

const graphQLFetcher = graphQLParams => {
  const query = graphQLParams.query

  return fetch(`${getGraphEndpoint()}graphql`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.aat}`,
      'sonraisecurity-com-org': getCurrentOrg(),
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify({
      query: `
          ${query}
          `,
      operationName: graphQLParams.operationName && graphQLParams.operationName,
      variables: graphQLParams.variables && graphQLParams.variables,
    }),
  }).then(response => {
    try {
      const parsed = response.json()
      return parsed
    } catch (err) {
      return {
        error: 'Error fetching response, likely due to search timeout',
        response: response,
      }
    }
  })
}

export class Graphiql extends React.Component {
  constructor(props) {
    super(props)

    const searchName =
      _.get(this.props, ['location', 'state', 'searchName']) ||
      qs.parse(_.get(this.props, ['location', 'search'], '')).searchName
    const query = this.props.savedSearches.find(
      search => search.get('name') === searchName
    )

    const preloadQuery =
      _.get(this.props, ['location', 'state', 'query']) ||
      (query ? query.get('query') : undefined)
    const variables = query ? query.get('variables') : undefined
    const welcomeMessage = `  
    # Welcome to Advanced Search
    #
    # Advanced Search is an in-browser tool for writing, validating, and
    # testing CQL queries.
    #
    # Type queries into this side of the screen, and you will see intelligent
    # typeaheads aware of the current CQL type schema and live syntax and
    # validation errors highlighted within the text.
    #
    # CQL queries typically start with a "{" character. Lines that starts
    # with a # are ignored.
    #
    # An example CQL query looks like:
    #
    #     {
    #       field(arg: "value") {
    #         subField
    #       }
    #     }
    #
    # Keyboard shortcuts:
    #
    #  Prettify Query:  Shift-Ctrl-P (or press the prettify button above)
    #
    #       Run Query:  Ctrl-Enter (or press the play button above)
    #
    #   Auto Complete:  Ctrl-Space (or just start typing)
    #
    `

    this.state = {
      query: query ? query.toJS().query : preloadQuery || welcomeMessage,
      variables: variables ? this.getVariables(variables.toJS()) : undefined,
      explorerIsOpen: false,
      metadata: null,
      srnUrlLinkParams: null,
      registedResultOnClick: false,
    }
  }

  componentWillUnmount() {
    const element = document.getElementsByClassName('CodeMirror-hints')
    if (element['0']) {
      const el = element['0']
      el.parentNode.removeChild(el)
    }
  }

  componentDidMount() {
    this.registerResultOnClick()
  }

  componentDidUpdate() {
    this.registerResultOnClick()
  }

  registerResultOnClick = () => {
    // there can be some special behaviours when the user clicks on result,
    // so we want to make sure we only register the click handler that enables
    // the behaviour one time
    if (this._graphiql && !this.state.registedResultOnClick) {
      const resultViewer = this._graphiql.resultComponent.viewer
      this.setState({ registedResultOnClick: true })
      resultViewer.on('focus', this.handleResultSelect)
      resultViewer.on('blur', this.handleResultSelect)
      resultViewer.on('cursorActivity', this.handleResultSelect)
    }
  }

  /**
   * This method will run when the user interacts with the search results, and
   * and set up state to enable the special behaviours
   */
  handleResultSelect = resultViewer => {
    if (!resultViewer) {
      return
    }
    const pos = resultViewer.getCursor()
    let line = resultViewer.getLine(pos.line)
    let token = resultViewer.getTokenAt(pos)

    // if the user has selected an SRN, we could show a link for them to nav to
    // it, so this part tries to get the SRN from the line
    if (line.trim().startsWith('"srn":')) {
      // line = something like `"srn": "srn:blahblahblah",`
      let srn = line
        .split(': ')[1] // take the value
        .replace('"', '') // strip of open quote mark
        .replace('"', '') // strip off trailing quote mark
      if (srn.endsWith(',')) {
        // strip off trailing comma
        srn = srn.substring(0, srn.length - 1)
      }

      // set in state is not the SRN - it is the params to navigate to that
      // node view:
      this.setState({ srnUrlLinkParams: getNodeViewPushParams(srn) })
    } else {
      this.setState({ srnUrlLinkParams: null })
    }

    // if the user selects metadata, we could show the metadata as a list or
    // object (toggle between views), so try to get the metadata value
    if (token.string === '"metadata"') {
      let currentLine = pos.line
      let line = resultViewer.getLine(currentLine).trim()

      // sometimes metadata is null, so just ignore this case
      if (line.includes('null')) {
        this.setState({ metadata: null })
        return
      }

      // sometimes the metadata is empty array, so handle this case
      if (line.endsWith(']') || line.endsWith('],')) {
        this.setState({ metadata: [] })
      }

      // choose whether the metadata is already being displayed as array
      // or object by it's opening character
      if (line.includes('{')) {
        this.getMetadataAsObject(resultViewer)
      } else {
        this.getMetadataAsList(resultViewer)
      }
    } else {
      // the user did not click on a metadata line in this case:
      this.setState({ metadata: null })
    }
  }

  /**
   * The metadata is being displayed as a list of strings - this method gets
   * the list of strings from the result viewer. The strings are one on each
   * line in the result viewer
   */
  getMetadataAsList = resultViewer => {
    const pos = resultViewer.getCursor()
    let currentLine = pos.line
    let line = resultViewer.getLine(currentLine).trim()

    // starting after the current line, we will iterate over all the lines in
    // the metadata until we get a close bracket, and put each line in the
    // lines list of lines
    const lines = []
    currentLine++
    line = resultViewer.getLine(currentLine).trim()
    while (line != ']' && line != '],') {
      lines.push(line)
      currentLine += 1
      line = resultViewer.getLine(currentLine).trim()
    }
    this.setState({
      metadata: {
        pos,
        value: lines,
      },
    })
  }

  /**
   * The metadata is being displayed as an object, and this method will
   * attempt to get the text for that object.
   */
  getMetadataAsObject = resultViewer => {
    const pos = resultViewer.getCursor()
    let currentLine = pos.line
    let line = resultViewer.getLine(currentLine)

    // handle case where metadata is an empty object
    if (line.endsWith('}') || line.endsWith('},')) {
      this.setState({ metadata: {} })
    }

    // it tries to find the end of the object by looking at how far indented
    // the start of the object is, and then finding another line with a close
    // brace on it that is also indented that far
    const prepentSpaces = line.match('[ ]+')[0]
    const endLine = prepentSpaces + '}'

    // begin keeping track of lines of text that represent our metadata object
    const lines = ['{']

    // add lines till we reach the end
    currentLine++
    line = resultViewer.getLine(currentLine)
    while (line != undefined && line !== endLine && line !== endLine + ',') {
      lines.push(line)
      currentLine++
      line = resultViewer.getLine(currentLine)
    }
    lines.push('}') // add close brace
    const content = lines.join('\n')
    const value = JSON.parse(content)
    this.setState({ metadata: { pos, value } })
  }

  /**
   * The user has decided they want to change the view of the metadata, so
   * we will set a new value in the resultViewer with the metadata changed
   */
  handleSetValue = () => {
    // when the value changes in the result viewer, it scrolls to the top,
    // so create a function to call that will reset the scroll to where it
    // was before.
    let codeMirrorScroll, scrollTopBefore
    if (this._container) {
      codeMirrorScroll = this._container.querySelector(
        '.result-window .CodeMirror-scroll'
      )
      scrollTopBefore = -1
      if (codeMirrorScroll) {
        scrollTopBefore = codeMirrorScroll.scrollTop
      }
    }

    // we reset the scroll on the next tick of the event loop, to make sure
    // it doesn't reset before the resultViewer scrolls itself to the top
    const handleScrollReset = () => {
      setTimeout(() => {
        if (scrollTopBefore >= 0) {
          codeMirrorScroll.scrollTop = scrollTopBefore
        }
      })
    }

    const { pos, value } = this.state.metadata
    if (!_.isArray(value)) {
      // if the metadata is an array now, we set the view to object
      this.handleSetValueFromObject()
      handleScrollReset()
      return
    }

    const resultViewer = this._graphiql.resultComponent.viewer
    const currentValue = resultViewer.getValue()
    const allLines = currentValue.split('\n')
    const newLines = [] // this will contain the new value

    // we add the current lines to the new lines until we reach the start of
    // the metadata object
    var lineIndex = 0
    while (lineIndex < pos.line) {
      newLines.push(allLines[lineIndex++])
    }

    // we transorm the lines of metadata lines text into the metadata object
    // and then stringify, and then split it by lines
    const newMetadataLines = JSON.stringify(
      metdataNestedObject(
        value.map((v, i) => {
          v = v.replace('"', '') // replace the open "
          if (i < value.length) {
            // if we're not on the last line, replace the close " and the ,
            return v.substring(0, v.length - 2)
          } else {
            // otherwise just replace the close "
            return v.substring(0, v.length - 1)
          }
        })
      ),
      null,
      2
    ).split('\n')

    // figure out how far we should indent each line
    const prepentSpaces = allLines[lineIndex].match('[ ]+')[0]

    // add all the lines for our metadata object
    for (var i = 0; i < newMetadataLines.length; i++) {
      var newLine = newMetadataLines[i]
      if (i === 0) {
        // if it's the first line, add the object key
        newLine = '"metadata": ' + newLine
      }
      newLines.push(prepentSpaces + newLine)
    }

    // now move the line index to after the metadata lines in the original
    // plus two extra lines that had the open and close braces on them
    lineIndex += value.length + 2

    // if the line after the metadata has a comma on the end, we add that here
    const lineAfter = allLines[lineIndex - 1]
    if (lineAfter.endsWith(',')) {
      newLines[newLines.length - 1] = newLines[newLines.length - 1] + ','
    }

    // now add all the rest of the lines from the original value
    while (lineIndex < allLines.length) {
      newLines.push(allLines[lineIndex++])
    }

    const newValue = newLines.join('\n')
    resultViewer.setValue(newValue)
    handleScrollReset()
  }

  /**
   * Our metadata is being viewed as an object, but the user wants to transform
   * it back to be viewed as a list - this method does that and sets the value
   * on the result viewer
   */
  handleSetValueFromObject = () => {
    const { pos, value } = this.state.metadata

    // first we need to take the object and transorm it into the list of
    // strings which is what this method does:
    const rawLines = [] // this will contain list of strings
    const currentPath = [] // some recursive state used by addKeys
    this.addKeys(currentPath, rawLines, value)

    const resultViewer = this._graphiql.resultComponent.viewer
    const currentValue = resultViewer.getValue()
    const allLines = currentValue.split('\n')
    const newLines = [] // this will hold our new value

    // add lines to the value that were before the metadata
    var lineIndex = 0
    while (lineIndex < pos.line) {
      newLines.push(allLines[lineIndex++])
    }

    // now add the metadata lines
    const prepentSpaces = allLines[lineIndex].match('[ ]+')[0]
    newLines.push(prepentSpaces + '"metadata": [')
    for (var i = 0; i < rawLines.length; i++) {
      var newLine = rawLines[i]
      // make sure we indent enough spaces, and add quotes around the string
      // each line gets a comma after, except for the last line
      newLines.push(
        prepentSpaces + '  ' + `"${newLine}"` + (i < rawLines.length ? ',' : '')
      )
    }
    newLines.push(prepentSpaces + ']')

    // now find where the end of the metadata object was in the original value
    // and iterate the line index until after it
    let line = allLines[lineIndex++]
    const endLine = prepentSpaces + '}'
    while (line != undefined && line !== endLine && line !== endLine + ',') {
      line = allLines[lineIndex++]
    }

    // if there was a comma after the metadata in the original value, add it back
    const lineAfter = allLines[lineIndex - 1]
    if (lineAfter && lineAfter.endsWith(',')) {
      newLines[newLines.length - 1] = newLines[newLines.length - 1] + ','
    }

    // now add the rest of the lines after the metadata
    while (lineIndex < allLines.length) {
      newLines.push(allLines[lineIndex++])
    }

    const newValue = newLines.join('\n')
    resultViewer.setValue(newValue)
  }

  /**
   * this method is used to tranform the metadata value back into its raw
   * list of strings value
   */
  addKeys = (currentPath, rawLines, value) => {
    // add more keys for eahc value in the object
    if (_.isObject(value)) {
      Object.keys(value).forEach(key => {
        const child = value[key]
        currentPath.push(key)
        this.addKeys(currentPath, rawLines, child)
        currentPath.pop(key)
      })
      return
    }

    // add more keys for each thing in the array
    if (_.isArray(value)) {
      value.forEach((child, index) => {
        currentPath.push(index)
        this.addKeys(currentPath, rawLines, child)
        currentPath.pop()
      })
      return
    }

    // it's not a collection, so stick the value in our result
    rawLines.push(currentPath.join('.') + ':' + value)
  }

  getVariable = ({ name, defaultValue }) => {
    if (typeof defaultValue === 'object') {
      ;`"${name}": { "value": ${defaultValue.value} }`
    }
    return `"${name}": ${defaultValue}`
  }

  getVariables = variables => {
    if (variables) {
      const { items } = variables
      if (!_.isEmpty(items)) {
        return `
{
  ${items.map(item => this.getVariable(item)).join(',\n  ')}
}
`
      }
    }
    return ''
  }

  loadSavedSearch = val => {
    exists(val)
      ? this.setState({
          query: val.value.query,
          variables: this.getVariables(val.value.variables),
        })
      : this.setState({ query: '', variables: '' })
  }

  handleQueryEdit = query => {
    this.setState({ query })
  }

  handleVariablesEdit = variables => {
    this.setState({ variables })
  }

  handleToggleExplorer = () => {
    this.setState(state => ({ explorerIsOpen: !state.explorerIsOpen }))
  }

  getDropdownOptions = () => {
    if (this.props.savedSearches.isEmpty()) {
      return []
    }
    return this.props.savedSearches
      .map(search =>
        fromJS({
          label: search.get('name'),
          value: {
            query: search.get('query'),
            variables: search.get('variables'),
          },
        })
      )
      .toJS()
  }

  handlePrettierClick = () => {
    try {
      const queryEditor = this._graphiql.queryEditorComponent.editor
      const currentValue = queryEditor.getValue()
      const prettierValue = prettier.format(currentValue, {
        parser: 'graphql',
        plugins: [graphqlParser],
      })
      queryEditor.setValue(prettierValue)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'an error occurred prettifying the query using prettier -- fallback to default implementation'
      )
      this._graphiql.handlePrettifyQuery()
    }
  }

  styles = {
    toolbarButton: {
      background: '#f0f0f0',
      border: '1px solid #c0c0c0',
      marginTop: '5px',
      marginLeft: '0.5em',
      marginBottom: '5px',
      height: '30px',
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingTop: '0.2em',
      paddingBottom: '0.5em',
      cursor: 'pointer',
      borderRadius: '3px',
      fontFamily: 'Roboto',
    },
  }

  render() {
    if (!_.isEmpty(this.props.schema)) {
      const schema = buildClientSchema(this.props.schema)
      return (
        <div
          id="graphiql"
          className="graphiql"
          ref={ref => (this._container = ref)}
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 0,
          }}
        >
          <GraphiQL
            id="graphiql"
            schema={schema}
            ref={ref => (this._graphiql = ref)}
            fetcher={graphQLFetcher}
            query={this.state.query}
            variables={this.state.variables}
            defaultQuery={this.state.query}
            onEditQuery={this.handleQueryEdit}
            onEditVariables={this.handleVariablesEdit}
          >
            <GraphiQL.Logo>
              <div style={{ fontFamily: 'Roboto' }}>
                <FormattedMessage {...messages.header} />
              </div>
            </GraphiQL.Logo>
            <GraphiQL.Toolbar>
              <div
                style={{
                  zIndex: LAYERS.TOOLBAR,
                  width: '600px',
                }}
              >
                <SelectBar
                  isClearable
                  styles={{
                    menuList: provided => ({
                      fontFamily: 'Roboto',
                      fontWeight: '300',
                      fontSize: '0.9rem',
                      ...provided,
                    }),
                    placeholder: provided => ({
                      fontFamily: 'Roboto',
                      fontWeight: '300',
                      fontSize: '0.9rem',
                      ...provided,
                    }),
                    singleValue: provided => ({
                      fontFamily: 'Roboto',
                      fontWeight: '300',
                      fontSize: '0.9rem',
                      ...provided,
                    }),
                  }}
                  onChange={this.loadSavedSearch}
                  placeholder="Select Saved Query..."
                  options={this.getDropdownOptions()}
                />
              </div>
              <div
                style={this.styles.toolbarButton}
                title="Prettify Query (Shift-Ctrl-P)"
                onClick={() => this.handlePrettierClick()}
              >
                Prettify
              </div>
              <div
                style={this.styles.toolbarButton}
                title="Show History"
                onClick={() => this._graphiql.handleToggleHistory()}
              >
                History
              </div>
              {this.state.metadata && (
                <div
                  style={this.styles.toolbarButton}
                  title="View Metadata"
                  onClick={this.handleSetValue}
                >
                  {_.isArray(this.state.metadata.value)
                    ? 'View Metadata as Object'
                    : 'View Metadata as Raw'}
                </div>
              )}
              {this.state.srnUrlLinkParams && (
                <a
                  href={`${this.state.srnUrlLinkParams.pathname}?${this.state.srnUrlLinkParams.search}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    style={this.styles.toolbarButton}
                    title="Go to ndoe view"
                  >
                    Go to node view
                  </div>
                </a>
              )}
            </GraphiQL.Toolbar>
          </GraphiQL>
        </div>
      )
    } else return <LoadingAnim />
  }
}

Graphiql.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      searchName: PropTypes.string,
      query: PropTypes.string,
    }),
  }),
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  schema: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  schema: selectGraphQLSchema,
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Graphiql)
