/*
 * GraphiQL Messages
 *
 * This contains all the text for the GraphExplorer component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.containers.GraphiQL.header',
    defaultMessage: 'Advanced Search',
  },
})
