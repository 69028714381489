/**
 *
 * GraphExplorer
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import _ from 'lodash'

import {
  selectSonraiSearches,
  selectGraphQLSchema,
} from 'containers/SonraiData/selectors'
import { fetchGraphQLSchema } from 'containers/SonraiData/actions'
import { createStructuredSelector } from 'reselect'
import Graphiql from 'containers/Graphiql'
import ImmutablePropTypes from 'react-immutable-proptypes'
import LoadingAnim from 'components/LoadingAnim'
import { selectSonraiSearchesLoading } from '../SonraiData/selectors'

export class GraphExplorer extends React.Component {
  constructor(props) {
    super(props)

    if (_.isEmpty(props.schema)) {
      props.fetchGraphQLSchema()
    }
  }

  render() {
    if (
      !window.aat ||
      (this.props.sonraiSearchesLoading &&
        this.props.savedSearches.isEmpty()) ||
      _.isEmpty(this.props.schema)
    ) {
      return <LoadingAnim />
    }

    return (
      <Graphiql
        location={this.props.location}
        savedSearches={this.props.savedSearches}
      />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  savedSearches: selectSonraiSearches,
  schema: selectGraphQLSchema,
  sonraiSearchesLoading: selectSonraiSearchesLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchGraphQLSchema,
    },
    dispatch
  )
}
const withConnect = connect(mapStateToProps, mapDispatchToProps)

GraphExplorer.propTypes = {
  fetchGraphQLSchema: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      preloadQuery: PropTypes.string,
    }),
  }),
  savedSearches: ImmutablePropTypes.iterable,
  schema: PropTypes.object,
  sonraiSearchesLoading: PropTypes.bool,
}
export default compose(withConnect)(GraphExplorer)
